import React from "react";

import "../../static/scss/scrolling-thumbnails.scss";

function ScrollingThumbnails() {
    return (
        <div className="uk-margin sliding-container">
            <div className="thumbnails-animation-right" />
            <div className="thumbnails-animation-left" />
        </div>
    );
}

export default ScrollingThumbnails;
