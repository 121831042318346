import { StaticImage } from "gatsby-plugin-image";
import UIkit from "uikit";
import React, { useEffect } from "react";

function Partners() {
    useEffect(() => {
        UIkit.scrollspy("#partners", {
            cls: "uk-animation-slide-left",
            delay: 250,
            target: "> div"
        });
    }, []);

    return (
        <div className="uk-section">
            <div className="uk-container uk-container-medium" id="partners">
                <div>
                    <h1 className="uk-text-center">Our Partners</h1>

                    <StaticImage
                        alt="Devices"
                        className="uk-align-center uk-visible@m"
                        placeholder="blurred"
                        src="../../static/images/partners.jpg"
                    />
                    <StaticImage
                        alt="Devices"
                        className="uk-align-center uk-hidden@m"
                        placeholder="blurred"
                        src="../../static/images/partners-mobile.jpg"
                    />

                    <p className="uk-text-center">
                        <a
                            className="button-purple uk-margin uk-button uk-button-large"
                            href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Start 7 day free trial
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Partners;
