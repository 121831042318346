/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { StaticImage } from "gatsby-plugin-image";
import UIkit from "uikit";
import React, { useEffect } from "react";

function Testimonials() {
    useEffect(() => {
        UIkit.slider("#testimonials", {
            autoplay: true,
            autoplayInterval: 3000,
            center: true,
            clsActivated: "uk-transition-active"
        });
    }, []);

    return (
        <div className="uk-margin-large-top uk-margin-large-bottom">
            <h1 style={{ color: "#131249", textAlign: "center" }}>What&apos;s the good word?</h1>

            <div id="testimonials">
                <div className="uk-position-relative uk-visible-toggle" tabIndex="-1">
                    <ul className="uk-slider-items uk-grid">
                        <li className="uk-width-3-4@s uk-width-1-1">
                            <div
                                className="uk-card uk-card-default uk-card-body uk-text-center"
                                style={{ backgroundColor: "rgb(237, 38, 118)", color: "white" }}
                            >
                                <p>
                                    &quot;Great value and great service! The app is fantastic and I can also use Hopster
                                    on my Apple TVs so the kids absolutely love it. Ad free and no inappropriate content
                                    so you don’t have to worry.&quot;
                                </p>
                                <p>
                                    <strong>xinam82</strong>
                                </p>
                                <StaticImage
                                    alt="5 Stars!"
                                    placeholder="none"
                                    src="../../static/images/stars.png"
                                    width={100}
                                />
                            </div>
                        </li>

                        <li className="uk-width-3-4@s uk-width-1-1">
                            <div
                                className="uk-card uk-card-default uk-card-body uk-text-center"
                                style={{ backgroundColor: "rgb(0, 172, 94)", color: "white" }}
                            >
                                <p>
                                    &quot;Every time my son opens this app I hear “wooow”. He absolutely loves this app,
                                    he can watch some of his favourite shows wherever we are. Most of all he loves the
                                    games, they are educational and really fun to play together and it’s great watching
                                    him learn!&quot;
                                </p>
                                <p>
                                    <strong>Lukimuss</strong>
                                </p>
                                <StaticImage
                                    alt="5 Stars!"
                                    placeholder="none"
                                    src="../../static/images/stars.png"
                                    width={100}
                                />
                            </div>
                        </li>

                        <li className="uk-width-3-4@s uk-width-1-1">
                            <div
                                className="uk-card uk-card-default uk-card-body uk-text-center"
                                style={{ backgroundColor: "rgb(239, 65, 35)", color: "white" }}
                            >
                                <p>
                                    &quot;We are half way through our trial with Hopster and can really recommend it.
                                    Unlike Netflix, which we trialled at the same time, you can totally relax knowing
                                    that your children are watching age appropriate programmes.&quot;
                                </p>
                                <p>
                                    <strong>Hilsed1</strong>
                                </p>
                                <StaticImage
                                    alt="5 Stars!"
                                    placeholder="none"
                                    src="../../static/images/stars.png"
                                    width={100}
                                />
                            </div>
                        </li>

                        <li className="uk-width-3-4@s uk-width-1-1">
                            <div
                                className="uk-card uk-card-default uk-card-body uk-text-center"
                                style={{ backgroundColor: "rgb(62, 62, 130)", color: "white" }}
                            >
                                <p>
                                    &quot;We totally love Hopster in our house. It’s always the first thing she wants
                                    every morning and throughout the day she always tells us what she has learnt! I love
                                    the drawing section and music too! Perfect entertainment for long car journeys and
                                    an extra 10mins in bed. Great!!!!&quot;
                                </p>
                                <p>
                                    <strong>FandLB</strong>
                                </p>
                                <StaticImage
                                    alt="5 Stars!"
                                    placeholder="none"
                                    src="../../static/images/stars.png"
                                    width={100}
                                />
                            </div>
                        </li>
                    </ul>
                </div>

                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" />
            </div>
        </div>
    );
}

export default Testimonials;
