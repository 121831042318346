import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "../../static/scss/hero.scss";

function Hero() {
    return (
        <div className="hero uk-section uk-background-cover uk-light">
            <div className="uk-container uk-container-medium" style={{ height: "100vh" }}>
                <div className="uk-flex uk-flex-middle uk-text-center uk-text-left@m uk-height-1-1">
                    <div className="uk-width-1-2@m uk-width-1-1">
                        <StaticImage
                            alt="Hopster!"
                            className="hero-logo"
                            placeholder="tracedSVG"
                            src="../../static/images/white.png"
                        />

                        <h2 className="">Making screentime SMART!</h2>

                        <p className="uk-padding-small" style={{ color: "white" }}>
                            The award winning, safe, interactive app filled with premium learning shows, games, books
                            and songs.
                        </p>

                        <a
                            href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                            className="button-pink uk-margin uk-button uk-button-large uk-animation-fade"
                        >
                            Start 7 day free trial
                        </a>

                        <div className="uk-flex uk-flex-left@m uk-flex-center uk-margin-top">
                            <a
                                className="uk-animation-fade"
                                href="https://itunes.apple.com/app/id689165391?mt=8"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <StaticImage
                                    alt="Apple App Store"
                                    src="../../static/images/button-apple.png"
                                    width={150}
                                />
                            </a>

                            <a
                                className="uk-animation-fade"
                                href="https://play.google.com/store/apps/details?id=tv.platomedia.hopster"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <StaticImage
                                    alt="Google Play Store"
                                    src="../../static/images/button-google.png"
                                    style={{ marginLeft: "10px" }}
                                    width={150}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
