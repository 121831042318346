import { StaticImage } from "gatsby-plugin-image";
import UIkit from "uikit";
import React, { useEffect } from "react";

import "../../static/scss/available.scss";

function Available() {
    useEffect(() => {
        UIkit.scrollspy("#available", {
            cls: "uk-animation-slide-right",
            delay: 250,
            target: "> div"
        });
    }, []);

    return (
        <div className="available uk-section uk-background-cover uk-light ">
            <div className="uk-container uk-container-medium" id="available">
                <div>
                    <h1 className="uk-text-center">Available on all devices</h1>

                    <StaticImage
                        alt="Devices"
                        className="uk-align-center"
                        placeholder="blurred"
                        src="../../static/images/devices.png"
                    />

                    <p className="uk-text-center">
                        <a
                            className="button-green uk-margin uk-button uk-button-large"
                            href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Start 7 day free trial
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Available;
