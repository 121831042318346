/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { StaticImage } from "gatsby-plugin-image";
import UIkit from "uikit";
import React, { useEffect } from "react";

function FeatureSlides() {
    useEffect(() => {
        const slider = document.querySelector(".uk-slider-container-offset");

        UIkit.slider(slider, {
            autoplay: true,
            autoplayInterval: 4000
        });
    }, []);

    return (
        <div className="uk-section" style={{ backgroundColor: "#ebf7fd" }}>
            <div className="uk-container uk-container-medium">
                <div className="uk-slider-container-offset">
                    <div className="uk-position-relative uk-visible-toggle" tabIndex="-1">
                        <ul className="uk-slider-items uk-child-width-3-4@m uk-grid">
                            <li>
                                <div className="uk-grid uk-flex-middle uk-child-width-1-2@m uk-child-width-1-1 uk-text-left@m uk-text-center">
                                    <div>
                                        <h2>A Safe Immersive Digital World</h2>

                                        <p className="uk-margin-small-top">
                                            A beautiful tropical island, easily navigated by children and always 100%
                                            ad-free.
                                        </p>

                                        <p>
                                            <a
                                                className="button-green uk-margin uk-button uk-button-large"
                                                href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Start 7 day free trial
                                            </a>
                                        </p>
                                    </div>

                                    <div>
                                        <StaticImage
                                            alt="safe"
                                            placeholder="blurred"
                                            src="../../static/images/slide_01.png"
                                        />
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="uk-grid uk-flex-middle uk-child-width-1-2@m uk-child-width-1-1 uk-text-left@m uk-text-center">
                                    <div>
                                        <h2>Responsible Autoplay Function</h2>

                                        <p className="uk-margin-small-top">
                                            We limit the number of video autoplays and change it up with a book or game.
                                        </p>

                                        <p>
                                            <a
                                                className="button-green uk-margin uk-button uk-button-large"
                                                href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Start 7 day free trial
                                            </a>
                                        </p>
                                    </div>

                                    <div>
                                        <StaticImage
                                            alt="responsible autoplay"
                                            placeholder="blurred"
                                            src="../../static/images/slide_02.png"
                                        />
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="uk-grid uk-flex-middle uk-child-width-1-2@m uk-child-width-1-1 uk-text-left@m uk-text-center">
                                    <div>
                                        <h2>Watch Anytime, Anywhere</h2>

                                        <p className="uk-margin-small-top">
                                            Download your little one’s favourites shows, games, songs and games -
                                            perfect when travelling.
                                        </p>

                                        <p>
                                            <a
                                                className="button-green uk-margin uk-button uk-button-large"
                                                href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Start 7 day free trial
                                            </a>
                                        </p>
                                    </div>

                                    <div>
                                        <StaticImage
                                            alt="watch anytime"
                                            placeholder="blurred"
                                            src="../../static/images/slide_03.png"
                                        />
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="uk-grid uk-flex-middle uk-child-width-1-2@m uk-child-width-1-1 uk-text-left@m uk-text-center">
                                    <div>
                                        <h2>Reports of Your Child&apos;s Learnings</h2>

                                        <p className="uk-margin-small-top">
                                            A monthly report that monitors what your little ones are watching, reading
                                            and playing.
                                        </p>

                                        <p>
                                            <a
                                                className="button-green uk-margin uk-button uk-button-large"
                                                href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Start 7 day free trial
                                            </a>
                                        </p>
                                    </div>

                                    <div>
                                        <StaticImage
                                            alt="reports of your child"
                                            placeholder="blurred"
                                            src="../../static/images/slide_04.png"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="uk-grid uk-flex-middle uk-child-width-1-2@m uk-child-width-1-1 uk-text-left@m uk-text-center">
                                    <div>
                                        <h2>Exclusive Award-Winning Hopster Originals</h2>

                                        <p className="uk-margin-small-top">
                                            Extraordinary content you can’t find anywhere else.
                                        </p>

                                        <p>
                                            <a
                                                className="button-green uk-margin uk-button uk-button-large"
                                                href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Start 7 day free trial
                                            </a>
                                        </p>
                                    </div>

                                    <div>
                                        <StaticImage
                                            alt="award winning"
                                            placeholder="blurred"
                                            src="../../static/images/slide_05.png"
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" />
                </div>
            </div>
        </div>
    );
}

export default FeatureSlides;
