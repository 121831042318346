import UIkit from "uikit";
import React, { useEffect } from "react";

import "../../static/scss/learning.scss";

function Learning() {
    useEffect(() => {
        UIkit.scrollspy("#learning", {
            cls: "uk-animation-fade",
            delay: 250,
            target: "> div"
        });
    }, []);

    return (
        <div className="uk-section uk-light about">
            <div className="uk-container uk-container-medium" style={{ height: "45rem" }}>
                <div className="uk-flex uk-flex-middle uk-text-center uk-text-left@m uk-height-1-1" id="learning">
                    <div className=" uk-width-1-2@m uk-width-1-1" style={{ color: "white" }}>
                        <h2>Where learning and fun become one!</h2>

                        <p>
                            Hundreds of educational, diverse, super fun shows that balance literacy, numeracy and
                            social-emotional learning
                            <br />
                            <br />
                            Curated in-line with the international Early Years National Curriculum to strengthen
                            literacy, numeracy and creativity with our interactive preschool content
                        </p>

                        <a
                            href="https://fp-promo.onelink.me/Z6bz/2d29b054"
                            className="button-orange uk-margin uk-button uk-button-large uk-animation-fade"
                        >
                            Start 7 day free trial
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Learning;
