import React from "react";

import Layout from "../components/layout";

import Available from "../components/index/available";
import FeatureSlides from "../components/index/feature-slides";
import Hero from "../components/index/hero";
import Learning from "../components/index/learning";
import Partners from "../components/index/partners";
import ScrollingThumbnails from "../components/index/scrolling-thumbnails";
import Testimonials from "../components/index/testimonials";

function Index() {
    return (
        <Layout>
            <Hero />
            <FeatureSlides />
            <Learning />
            <ScrollingThumbnails />
            <Testimonials />
            <Available />
            <Partners />
        </Layout>
    );
}

export default Index;
